export default function sliderHeroLine() {
  if (document.querySelector(".slider-hero-line") != null) {
    const sliderHeroLine = new Swiper(".slider-hero-line", {
      loop: true,
      centered: true,
      spaceBetween: 0,
      speed: 500,
      autoplay: {
        delay: 5000,
      },
      effect: "fade"
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  sliderHeroLine();
});
